.panel-chats-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.panel-chats-container hr{
    margin: 0 20px 0;
}

.panel-chats-box{
    padding: 0 10px;
}

.chat-box{
    display: grid;
    grid-template-columns: 1fr 5fr;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    border-radius: 5px;
    padding: 10px 0;
    box-shadow: 0 0 1px black;
    margin: 2px 0;
    cursor: pointer;
}

.chat-box:hover{
    background-color: rgb(228, 228, 228);
}

.chat-avatar img, .chat-avatar svg{
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.chat-avatar svg{
    color: rgb(200, 200, 200);
    background-color: rgb(231, 231, 231);
}

.chat-avatar{
    display: flex;
    justify-content: center;
    align-self: center;
}

.chat-name-and-desc h3{
    margin: 0;
}

.last-message{
    display: flex;
    justify-content: space-between;
    margin: 0 20px 0 0;
}

.chat-name-and-desc p{
    font-size: 14px;
    color: rgb(180, 180, 180);
    margin: 5px 0;
}

.chat-name-and-desc span{
    font-size: 12px;
    color: rgb(180, 180, 180);
    margin: 5px 0;
}

@media (max-width: 1100px) {
    .chat-name-and-desc h3{
      font-size: 0.95rem;
    }
    .last-message p{
        font-size: 12px;
    }
    .last-message span{
        font-size: 10px;
    }
    .chat-avatar img {
        width: 32px;
        height: 32px;
    }
    .chat-box{
        gap: 5px;
    }
}

@media (max-width: 900px) {
    .chat-name-and-desc h3{
      font-size: 0.9rem;
    }
    .last-message p{
        font-size: 11px;
    }
    .last-message span{
        font-size: 9px;
    }
    .chat-avatar img {
        width: 28px;
        height: 28px;
    }
    .chat-box{
        padding: 10px 0px 10px 2px;
    }
}

@media (max-width: 800px) {
    .chat-name-and-desc h3{
      font-size: 1.1rem;
    }
    .last-message p{
        font-size: 14px;
    }
    .last-message span{
        font-size: 12px;
    }
    .chat-avatar img {
        width: 35px;
        height: 35px;
    }
    .chat-box{
        grid-template-columns: 1fr 6.5fr;
    }
}

@media (max-width: 600px) {
    .chat-box{
        grid-template-columns: 1fr 5fr;
    }
}

@media (max-width: 500px) {
    .chat-name-and-desc h3{
      font-size: 1rem;
    }
    .last-message p{
        font-size: 13px;
    }
    .last-message span{
        font-size: 11px;
    }
    .chat-avatar img {
        width: 28px;
        height: 28px;
    }
}