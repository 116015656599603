.join-group-container{
    text-align: center;
}

.join-group{
    width: 75%;
}

.join-group-search-bar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.join-group-input{
    width: 100%;
    min-width: 15rem;
    height: 2.5rem;
    border-radius: 17px;
    border: 1px solid rgb(210, 210, 210);
    box-shadow: 0 0 2px rgb(210, 210, 210);
    text-align: center;
    color: black;
}

.searched-groups{
    position: absolute;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    z-index: 5;
    box-shadow: 3px 3px 3px rgb(153, 153, 153);
}

.return-arrow-div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.return-arrow{
    cursor: pointer;
}

.return-arrow:hover{
    box-shadow: 0 0 3px #545454;
    border-radius: 50%;
    padding: 2px;
}

@media (max-width: 350px) {
    .join-group-input{
        min-width: 12rem;
    }
}