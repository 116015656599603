.auth-page{
    display: grid;
    grid-template-columns: 3fr 1fr;
    height: 100vh;
}

.auth-page-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url("../../Images/Login-Image.avif") center no-repeat;
    background-size: cover;
}

.auth-page img{
    height: 100%;
    width: auto;
}

.auth-image{
    background: url("../../Images/Login-Image.avif") center no-repeat;
    background-size: cover;
}

.auth-sidebar{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0px 3rem;
}

.auth-sidebar-mobile{
    display: flex;
    align-items: center;
    padding: 0.5rem 2rem;
}

.auth-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.auth-toggle-link{
    cursor: pointer;
}

.signup-comp {
    display: flex;
    flex-direction: column;
    width: 370px;
}

.signup-comp h1 {
    margin: 40px 0 30px;
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 255, 0.237);
    padding: 5px;
}

.register-form-box {
    font-size: 1.2rem;  
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.login-form-box {
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.login-form-box input, .register-form-box input {
    border-radius: 5px;
}

.form-email {
    height: 30px;
    margin: 10px 0px 10px 0px !important;
}

.btn-register {
    font-size: 1.2rem;
    padding: 5px 7px;
    border: 1px;
    border-radius: 5px;
    margin: 15px 0;
    color: blue;
    border: 1px solid rgba(0, 0, 255, 0.237);
}

.btn-register:hover{
    cursor: pointer;
    border: 1px solid blue;
}

.form-password {
    height: 30px;
    margin: 10px 0px 10px 0px !important;
}

#form-picture{
    background-image: none;
    border: none;
}

.picture-input-container{
    display: flex;
    gap: 20px;
    margin: 10px 0 -10px;
}

.picture-input-div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1px;
}

@media (max-width: 1000px){
    .signup-comp {
        width: 320px;    
    }
}

@media (max-width: 600px){
    .signup-comp h1 {
        font-size: 1.6rem;
    }
}

@media (max-width: 500px){
    .signup-comp {
        width: 300px;    
    }
    .form-email input, .form-password input {
        height: 1.1rem;
    }
}

@media (max-width: 400px){
    .signup-comp {
        width: 250px;    
    }
    .signup-comp h1 {
        font-size: 1.4rem;
    }
    .picture-input-container label{
        font-size: 1rem;
    }
    .auth-toggle-paragraph{
        font-size: 0.9rem;
    }
}

@media (max-width: 360px){
    .signup-comp {
        width: 200px;    
    }
    .signup-comp h1 {
        font-size: 1.3rem;
    }
    .picture-input-container label{
        font-size: 0.95rem;
    }
    .auth-toggle-paragraph{
        font-size: 0.8rem;
    }
    #form-picture{
        font-size: 0.8rem;
    }
    .picture-input-container{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 10px 0 -10px;
    }
}