.sidebar-container{
    display: grid;
    grid-template-rows: 1fr 6fr;
    gap: 50px;
    height: 90vh;
}

.left-panel-box{
    overflow-y: scroll;
    scrollbar-width: none;
}

.left-panel-box::-webkit-scrollbar {
    display: none;
}