.modal-image{
    max-width: 100%;
    max-height: 90%;
}

.change-profile-pic-div{
    margin: 10px auto;
}

.change-profile-pic-div button{
    background-color: rgb(68, 68, 186);
    color: white;
    border: none;
    border-radius: 17px;
    padding: 5px 7px;
}

.change-profile-pic-div button:hover{
    background-color: rgb(22, 22, 255);
}
