.welcome-container{
    display: flex;
    flex: 0.7;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: rgba(0, 0, 0, 0.54);
    border-radius: 20px;
}

.welcome-container img{
    height: 20vw;
    width: auto;
}